import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import assetsImage from "../assets/assets";
import Slider from "react-slick";
import Testimonials from "../Components/Testimonials";
import { VideoHTMLAttributes } from "react";
const About = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div>
      <Navbar />
      <section className="intro" id="about">
        <div className="container pt-5" data-aos="fade-up">
          <div className="row">
            {/* <div
              className="col-lg-6 d-none order-1 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div
                className="introVideo position-relative"
                data-aos="fade-right"
                data-aos-delay="50"
                data-aos-duration="3000"
              >
                <video
                  src={assetsImage.introVid}
                  width="100%"
                  height={450}
                  autoplay="true"
                  loop="true"
                  controls
                />
              </div>
            </div> */}

            <div
              className="col-lg-12 pt-4 py-5 order-2 order-lg-1 content"
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div className="section-title text-center">
                <h2 className="">Who we are</h2>

                <p className="text-white">
                  <span className="theme-color">Techscope</span> Intro
                </p>
              </div>
              <p className="text-white">
                At TechScope, we are passionate about empowering businesses and
                individuals to achieve their full potential through technology.
                As a full-service tech company, we offer a comprehensive range
                of training, staffing, and consulting services designed to help
                our clients stay ahead of the curve. Our team of experts has
                extensive experience and knowledge in a variety of technical
                fields, and we are dedicated to delivering high-quality
                solutions that meet the unique needs of each client. Whether you
                are a business looking to upskill your workforce, an individual
                seeking to advance your career, or a company in need of a
                trusted partner for your next project, TechScope has the
                expertise and resources to help you succeed.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="about" id="about">
        <div className="container py-5" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 pt-lg-5 order-lg-2"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div className="section-title">
                <h2>Support for student</h2>

                <p className="text-white">
                  <span className="theme-color">Student</span> Support
                </p>
              </div>
              <p className="text-white pe-4">
                At our bootcamp, we go beyond technical training to provide
                comprehensive support to our students in their job search and
                career development. From the moment they join us, we offer
                personalized guidance, resume building, and interview
                preparation to help them showcase their skills effectively. We
                leverage our industry connections and actively promote our
                students to potential employers, ensuring they have access to
                relevant job opportunities. Once our students secure a job, our
                support doesn't end there. We provide ongoing mentorship,
                professional development resources, and a strong alumni network
                to help them thrive in their roles and continually grow their
                careers.
              </p>
            </div>

            <div
              className="col-lg-6 pb-4 content"
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div
                className="position-relative"
                data-aos="fade-right"
                data-aos-delay="50"
                data-aos-duration="1000"
              >
                <img className="img-fluid" src={assetsImage.intro} alt="" />
                <div id="html-spinner" className="spinner"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="community" id="cummunity">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-12 col-md-6"
              data-aos="fade-right"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <div className="section-title">
                <h2>Community Involvement</h2>

                <p className="text-white">
                  Community <span className="theme-color">Involvement</span>
                </p>
              </div>
              <div className="text-white">
                <p>
                  At TechScope, we believe in giving back to the communities we
                  serve and making a positive impact wherever we can. That's why
                  we are proud to be part of a group of 30,000 members committed
                  to making a difference in the lives of others. Whether it's
                  through volunteer work, community outreach, or supporting
                  local organizations, we are always looking for ways to get
                  involved and make a positive impact.
                </p>
                <p>
                  We are also proud to be a local company, with roots firmly
                  planted in the heart of NYC. We understand the unique
                  challenges and opportunities of doing business in this vibrant
                  city, and we are dedicated to helping our neighbors and
                  colleagues succeed. Whether you are a local business or an
                  individual looking to advance your career, TechScope is here
                  to help, with the expertise and resources you need to succeed.
                </p>
              </div>
            </div>
            <div
              className="col-sm-12 col-md-6 p-5"
              data-aos="fade-left"
              data-aos-delay="50"
              data-aos-duration="3000"
            >
              <img className="img-fluid" alt="" src={assetsImage.community} />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- ======= Team Section ======= --> */}

      <section className="team" id="team">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>Team</h2>

            <p className="text-white">
              Our talented <span className="theme-color">Team</span>
            </p>
          </div>

          <div
            className="row rounded my-4 mx-1"
            style={{ backgroundColor: "#151515" }}
          >
            <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
              <div
                className="member"
                data-aos="fade-up"
                data-aos-delay="100"
                data-aos-duration="300"
              >
                <div className="member-img p-3">
                  <img
                    alt=""
                    className="img-fluid rounded"
                    src={assetsImage.rohanCEO}
                  />
                  <div className="social"></div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-6 d-flex align-items-stretch">
              <div className="ceo-message p-3 p-lg-5">
                <div className="qoute-start">
                  <img
                    className="img-fluid"
                    src={assetsImage.qouteStart}
                    alt=""
                  ></img>
                </div>
                <div className="member-info text-white align-object-center pt-lg-5 py-3">
                  <p>
                    At Techscope, we believe innovation begins from within and
                    is nurtured by an expansive vision. Our culture encourages
                    creativity and embraces diverse perspectives, enabling us to
                    transcend current limitations and achieve transformative
                    breakthroughs
                  </p>
                  <h4>Rohan Hossain</h4>
                  <span>Founder and CEO</span>
                </div>
                <div className="qoute-start text-end">
                  <img
                    className="img-fluid"
                    src={assetsImage.qouteEnd}
                    alt=""
                  ></img>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div
                className="member"
                data-aos="fade-up"
                data-aos-delay="300"
                data-aos-duration="500"
              >
                <div className="member-img">
                  <img alt="" className="img-fluid" src={assetsImage.awais} />
                  <div className="social"></div>
                </div>

                <div className="member-info">
                  <h4>Awais Nayyar</h4>
                  <span>Chief Product Officer</span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div
                className="member"
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-duration="600"
              >
                <div className="member-img">
                  <img alt="" className="img-fluid" src={assetsImage.jubaer} />
                  <div className="social"></div>
                </div>

                <div className="member-info">
                  <h4>Jubaer Islam</h4>
                  <span>Chief Technical Officer</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div
                className="member"
                data-aos="fade-up"
                data-aos-delay="600"
                data-aos-duration="800"
              >
                <div className="member-img">
                  <img alt="" className="img-fluid" src={assetsImage.junaid} />
                  <div className="social"></div>
                </div>

                <div className="member-info">
                  <h4>Junaid Islam</h4>
                  <span>Chief Operating Officer</span>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
              <div
                className="member"
                data-aos="fade-up"
                data-aos-delay="700"
                data-aos-duration="900"
              >
                <div className="member-img">
                  <img alt="" className="img-fluid" src={assetsImage.afran} />
                  <div className="social"></div>
                </div>

                <div className="member-info">
                  <h4>Afran Hossain</h4>
                  <span>Chief Financial Officer</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Testimonials />
      {/* <!-- End Testimonials Section --> */}
      <section className="events-slider" data-aos="fade-up">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-4">
              <div className="section-title">
                <h2>Our Event Gallery</h2>
                <p className="text-white">
                  Meet & <span className="theme-color">Greet</span>
                </p>
                <span className="fs-6 text-white">
                  On this momentous occasion, our esteemed tech institute opens
                  its doors to students, faculty, industry professionals, and
                  enthusiasts alike, to celebrate the spirit of innovation and
                  collaboration. With the aim of fostering a vibrant tech
                  community, we have organized this event to provide a platform
                  for exchanging ideas, forging partnerships, and exploring
                  groundbreaking technologies
                </span>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-8">
              <Slider {...settings}>
                <div className="px-2">
                  <img
                    src={assetsImage.event1}
                    className="d-block w-100 rounded"
                    alt="..."
                  />
                </div>
                <div className="px-2">
                  <img
                    src={assetsImage.event2}
                    className="d-block w-100 rounded"
                    alt="..."
                  />
                </div>
                <div className="px-2">
                  <img
                    src={assetsImage.event3}
                    className="d-block w-100 rounded"
                    alt="..."
                  />
                </div>
                <div className="px-2">
                  <img
                    src={assetsImage.event4}
                    className="d-block w-100 rounded"
                    alt="..."
                  />
                </div>
                <div className="px-2">
                  <img
                    src={assetsImage.event3}
                    className="d-block w-100 rounded"
                    alt="..."
                  />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default About;
