import "./App.css?v=1.4";
import "bootstrap/dist/css/bootstrap.min.css";
import "./contact.css";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Consulting from "./Pages/Consulting";
import Corporate from "./Pages/Corporate";
import Staffing from "./Pages/Staffing";
import Training from "./Pages/Training";
import Contact from "./Pages/Contact";
import Blogs from "./Pages/Blogs";
import Blogsdetail from "./Pages/Blogsdetail";
import Footer from "./Components/Footer";
import WebAppDev from "./Pages/WebAppDev";
import Page404 from "./Pages/Page404";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/corporate/consulting" element={<Consulting />} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/corporate/talent-solutions" element={<Staffing />} />
        <Route path="/training" element={<Training />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Blogs" element={<Blogs />} />
        <Route path="/Web&App-Development" element={<WebAppDev />} />
        <Route path="/Blogs/blog-name" element={<Blogsdetail />} />
        <Route path="/*" element={<Page404 />}></Route>
      </Routes>

      <Footer />
    </div>
  );
}

export default App;
