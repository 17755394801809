import React, { useEffect } from "react";
import Navbar from "../Components/Navbar";
import assetsImage from "../assets/assets";
import { useForm } from "@formspree/react";
import Thanks from "../Components/Thanks";
import { FaMapMarkerAlt, FaEnvelope, FaPhoneAlt } from "react-icons/fa";
const Contact = () => {
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const [state, handleSubmit] = useForm("xgejyepk");
  if (state.succeeded) {
    return <Thanks />;
  }
  return (
    <div>
      <Navbar />
      <section className="contact-us pb-xs-80 pt-xs-80 pt-sm-100 pb-sm-100 pt-md-100 pb-md-100 pt-120 pb-120 overflow-hidden">
        <div className="container py-4 py-sm-4 py-lg-5">
          <div className="row">
            <div className="col-lg-6 py-lg-3">
              <div className="section-title pt-2">
                <h2>contact us with Ease</h2>
                <p className="text-white">
                  Get in <span className="theme-color"> Touch</span> us
                </p>
              </div>
              <div
                className="contact-us__content wow fadeInUp"
                data-wow-delay=".3s"
              >
                <div className="description text-white">
                  <p>
                    Empower your data journey with TechScope's tailored
                    solutions in data science, data engineering, and data
                    analytics. Reach out to us today to explore how we can
                    support your objectives and drive success together.
                  </p>
                </div>
              </div>
            </div>

            <div className="col-lg-6 py-lg-4">
              <div className="row contact-us__item-wrapper pb-4">
                <div className="col-sm-6">
                  <div
                    className="contact-us__item mb-40 wow fadeInUp"
                    data-wow-delay=".9s"
                  >
                    <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon color-primary">
                        <i className="primary-color">
                          <FaEnvelope />
                        </i>
                      </div>

                      <h4 className="title text-white fw-bold">Email Us</h4>
                    </div>

                    <div className="contact-us__item-body font-la">
                      <h6 className="text-white fw-bold">
                        For Courses Information:
                      </h6>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-white text-decoration-none"
                            href="mailto:courses@techscope.org"
                          >
                            courses@techscope.org{" "}
                          </a>
                        </li>
                      </ul>
                      <h6 className="text-white fw-bold">
                        For Talent Solutions:
                      </h6>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-white text-decoration-none"
                            href="mailto:staffing@techscope.org"
                          >
                            staffing@techscope.org{" "}
                          </a>
                        </li>
                      </ul>
                      <h6 className="text-white fw-bold">
                        For Consulting Needs:
                      </h6>
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-white text-decoration-none"
                            href="mailto:consulting@techscope.org"
                          >
                            consulting@techscope.org{" "}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="contact-us__item pb-4">
                    <div className="contact-us__item-header mb-25 mb-md-20 mb-sm-15 mb-xs-10 d-flex align-items-center">
                      <div className="icon">
                        <i className="primary-color">
                          <FaMapMarkerAlt />
                        </i>
                      </div>

                      <h4 className="title text-white fw-bold">
                        Office Location
                      </h4>
                    </div>

                    <div className="text-white">
                      255-12 Hillside Ave, Floral Park, NY 11004
                    </div>
                  </div>
                  <div
                    className="contact-us__item mb-40 wow fadeInUp"
                    data-wow-delay=".7s"
                  >
                    <div className="contact-us__item-header d-flex align-items-center">
                      <div className="icon mr-10 color-primary">
                        <i className="primary-color">
                          <FaPhoneAlt />
                        </i>
                      </div>

                      <h4 className="title text-white fw-bold">Call Us</h4>
                    </div>

                    <div className="contact-us__item-body ">
                      <ul className="list-unstyled">
                        <li>
                          <a
                            className="text-white text-decoration-none"
                            href="tell:+1 (929) 235-5888"
                          >
                            +1 (929) 235-5888
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 primary-color">
              <hr className="primary-color" />
            </div>
          </div>
        </div>
      </section>
      {/* <!-- contact-us form end --> */}
      <section className="contact-form  mb-xs-80 mb-sm-100 mb-md-100 mb-120 overflow-hidden">
        <div id="contact-map" className="mb-sm-30 mb-xs-25">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3023.171183463619!2d-73.71493182428804!3d40.73625863613071!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c26267ffb1f1c5%3A0x7ce7a74d5ec06c2d!2s255-12%20Hillside%20Ave.%2C%20Queens%2C%20NY%2011001%2C%20USA!5e0!3m2!1sen!2s!4v1709326204152!5m2!1sen!2s"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
        {/* <!-- contact-map --> */}

        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="contact-form py-4">
                <form
                  className="form px-5 contact-form"
                  onSubmit={handleSubmit}
                >
                  <div className="section-title pt-2 pb-0">
                    <h2>Fill out the complete form</h2>
                    <p className="text-white">
                      <span className="theme-color">Contact</span> us
                    </p>
                  </div>
                  <div className="form-group mb-4">
                    <input
                      className="contact-input-field"
                      type="email"
                      name="Email:"
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="Email:" className="label-name">
                      <span className="content-name">Email:</span>
                    </label>
                  </div>
                  <div className="form-group mb-4">
                    <input
                      className="contact-input-field"
                      type="text"
                      name="Full name"
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="name" className="label-name">
                      <span className="content-name">Full Name:</span>
                    </label>
                  </div>
                  <div className="mb-5">
                    <p className="label-name">
                      What services are you inquiring about?
                    </p>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="What services are you inquiring about?"
                      id="Training"
                      value="Training"
                    />
                    <span className="px-3"> Training</span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="What services are you inquiring about?"
                      id="Staffing"
                      value="Staffing"
                    />
                    <span className="px-3"> Staffing</span>
                    <div className="py-2">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="What services are you inquiring about?"
                        id="Consulting"
                        value="Consulting"
                      />
                      <span className="px-3"> Consulting</span>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="What services are you inquiring about?"
                        id="Partnering"
                        value="Partnering"
                      />
                      <span className="px-3"> Partnering</span>
                    </div>
                  </div>
                  <div className="mb-5">
                    <p className="label-name">Best time to reach out?</p>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Best time to reach out?"
                      id="Morning"
                      value="Morning"
                    />
                    <span className="px-3"> Morning</span>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Best time to reach out?"
                      id="Evening"
                      value="Evening"
                    />
                    <span className="px-3"> Evening</span>
                  </div>
                  <div className="mb-5">
                    <p className="label-name">How did you hear about us?</p>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="How did you hear about us?"
                      id="hear1"
                      value="Website"
                    />
                    <span className="px-3"> Website</span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="How did you hear about us?"
                      id="hear3"
                      value="Friend"
                    />
                    <span className="px-3"> Friend</span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="How did you hear about us?"
                      id="hear4"
                      value="Newsletter"
                    />
                    <span className="px-3"> Newsletter</span>
                    <div className="py-2"></div>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="How did you hear about us?"
                      id="hear5"
                      value="Advertisement"
                    />
                    <span className="px-3"> Advertisement</span>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="How did you hear about us?"
                      id="hear6"
                      value="Social Media"
                    />
                    <span className="px-3"> Social Media</span>
                  </div>
                  {/* <div className="form-group mb-5">
                    <input
                      className="contact-input-field"
                      type="text"
                      name="What's your highest level of education?"
                      id="education"
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="name" className="label-name">
                      <span className="content-name">
                        What's your highest level of education?
                      </span>
                    </label>
                  </div> */}
                  {/* <div className="mb-5">
                    <p className="label-name">Are you at least 21 years old?</p>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Are you at least 21 years old?"
                      id="years1"
                      value="Yes"
                    />
                    <span className="px-3"> Yes</span>
                    <input
                      className="form-check-input"
                      type="radio"
                      name="Are you at least 21 years old?"
                      id="years2"
                      value="No"
                    />
                    <span className="px-3"> No</span>
                  </div> */}

                  {/* <div className="form-group mb-5">
                    <input
                      className="contact-input-field"
                      type="tel"
                      name="Contact Number:"
                      id="tel"
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="name" className="label-name">
                      <span className="content-name">
                        Please provide the best contact number to reach you:
                      </span>
                    </label>
                  </div> */}

                  <div className="form-group mb-5">
                    <input
                      className="contact-input-field"
                      type="text"
                      name="Comments:"
                      required
                      autoComplete="off"
                    />
                    <label htmlFor="name" className="label-name">
                      <span className="content-name">
                        Comments and/or questions
                      </span>
                    </label>
                  </div>

                  <button
                    type="submit"
                    disabled={state.submitting}
                    className="theme-btn btn-sm btn-yellow"
                  >
                    Submit Message{" "}
                    <i className="far fa-chevron-double-right"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- contact-us form end --> */}
    </div>
  );
};

export default Contact;
